<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- Begin information block -->
      <lims-block>
        <modal-remove-button
          ref="removeButtonModal"
          slot="blockTitle"
          @onContinue="onConfirmRemoveButton"
        ></modal-remove-button>
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/personalised-report/form/information/blockTitle') }}
        </h4>
        <div slot="blockContent">
          <div>
            <md-button
              class="assign-button-width"
              :style="{ 'background-color': `${rgbColor[item.buttonColorName]} !important` }"
              @click="onSelectButton(item, index)"
              v-for="(item, index) in buttonList"
              :key="index"
              ><span style="font-weight: 700; font-size: 14px">{{ item.title }}</span
              ><span>{{ item.subTitle }}</span></md-button
            >
          </div>
        </div>
      </lims-block>
      <!-- End information block -->
      <!-- Begin report block -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/personalised-report/form/report/blockTitle') }}</h4>
        <collapse
          slot="blockContent"
          :wrapperClass="'case-collapse'"
          :collapse="['Billing Data']"
          icon="keyboard_arrow_down"
        >
          <div slot="md-collapse-pane-1">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="personalisedReportSchema.report" field="buttonName">
                  <md-input slot="field" v-model="formData.name" type="text" maxlength="50" tabenable="yes"></md-input>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="personalisedReportSchema.report" field="personalReport">
                  <v-select
                    slot="field"
                    :placeholder="$t('entities/personalised-report/form/placeholder/personalizedReport')"
                    :options="personalReportList"
                    :reduce="(item) => item.casePersonalizedReportId"
                    label="title"
                    v-model="formData.reportId"
                    tabenable="yes"
                    :tabindex="1"
                  >
                    <template #option="{ title }">{{ title }}</template>
                    <template #selected-option="{ title }">{{ title }}</template>
                  </v-select>
                </lims-field>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="personalisedReportSchema.report" field="buttonTitle">
                  <md-input slot="field" v-model="formData.title" type="text" maxlength="50" tabenable="yes"></md-input>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="personalisedReportSchema.report" field="buttonSubTitle">
                  <md-input
                    slot="field"
                    v-model="formData.subTitle"
                    type="text"
                    maxlength="50"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="personalisedReportSchema.report" field="buttonColor">
                  <lims-single-select
                    slot="field"
                    :placeholder="$t('entities/personalised-report/form/placeholder/colorButton')"
                    :options="colorList"
                    :custom-color="true"
                    reduceKey="fieldItemId"
                    labelKey="fieldItemName"
                    v-model="formData.buttonColorId"
                    @input="changeColor"
                  >
                    <template #option="{ name }">{{ name }}</template>
                    <template #selected-option="{ name }">{{ name }}</template>
                  </lims-single-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field
                  class="textarea-field"
                  :model="formData"
                  :schema="personalisedReportSchema.report"
                  field="buttonNote"
                >
                  <md-textarea
                    slot="field"
                    type="text"
                    maxlength="1000"
                    v-model="formData.notes"
                    tabenable="yes"
                  ></md-textarea>
                </lims-field>
              </div>
            </div>
          </div>
        </collapse>
      </lims-block>
      <!-- End report block -->
      <!-- Begin action block -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onClose" class="md-button lims-form-button md-theme-default">
            {{ $t('global/button/button.close') }}
          </md-button>
          <md-button class="md-primary lims-form-button" @click="onSave">{{
            $t('global/button/button.save')
          }}</md-button>
          <md-button v-if="selectedId" class="md-danger lims-form-button" @click="onRemoveButton">{{
            $t('global/button/button.removeButton')
          }}</md-button>
        </div>
      </div>
      <!-- End action block -->
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import { APP_ROUTES, RGB_COLORS } from '@/core/constants';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import { getPersonalisedReportSchema } from '@/schemas/personalised-report.schema';
import { DropdownService, PersonalizedReportService } from '@/services';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import Collapse from '@/components/Lims/LimsCollapse';
import ModalRemoveButton from '@/components/Lims/modals/ModalRemoveButton';
const NUMBER_BUTTON_DEFAULT = 20;
// TODO: MOVE TO API CONFIG LATER
const BUTTON_COLOR_ID = 20294; // gray

export default {
  components: { ModalRemoveButton, Collapse, LimsBlock },
  name: 'PersonalisedReportForm',
  props: {
    pathologistId: {
      required: false,
    },
    pathologistName: {
      required: false,
    },
    personalizedReportId: {
      required: false,
    },
  },
  mixins: [FormMixins, TabMixins],
  data() {
    return {
      buttonList: [],
      specialtyOption: [],
      personalReportList: [],
      personalReportDefaultList: [],
      personalReportDefaultButtonList: [],
      colorList: [],
      formData: {},
      selectedId: null,
      isPersonalisedReportFormMode: false,
    };
  },
  computed: {
    personalisedReportSchema: function () {
      return getPersonalisedReportSchema();
    },
    rgbColor() {
      return RGB_COLORS;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const colorList = await DropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.BUTTON_COLOR]);
      this.colorList = colorList.ButtonColors;
      if (this.pathologistId) {
        await this.getAssignButtonListByPathologistId();
      } else {
        this.isPersonalisedReportFormMode = true;
        await this.getAssignButtonListByPersonalisedReportId();
      }
    },
    async getAssignButtonListByPathologistId() {
      const { data, error } = await PersonalizedReportService.getAssignButtonListByPathologistId(this.pathologistId);
      if (error) {
        this.createButtonListDefault([]);
        this.$alertSuccess(
          this.$t('pages/personalised-report/form/assign-button/alertSuccess/warningNoPersonalizeReport', {
            pathologistFullName: this.pathologistName,
          }),
        );
      } else {
        if (data) {
          this.selectFirstButtonWhenReloadPage(data);
        }
      }
    },
    async getAssignButtonListByPersonalisedReportId() {
      const { data, error } = await PersonalizedReportService.getAssignButtonListByPersonalisedReportId(
        this.personalizedReportId,
      );
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.selectFirstButtonWhenReloadPage(data);
        }
      }
    },
    selectFirstButtonWhenReloadPage(data) {
      this.createButtonListDefault(data.personalizedReportButtons);
      this.personalReportList = this.filterPersonalizedReportList(
        data.personalizedReportButtons,
        data.personalizedReports,
      );
      this.personalReportDefaultList = data.personalizedReports;
      this.personalReportDefaultButtonList = data.personalizedReportButtons;
      this.formData.buttonColorId = BUTTON_COLOR_ID;
      if (this.isPersonalisedReportFormMode) {
        this.buttonList = data.personalizedReportButtons;
      }
      if (this.buttonList && this.buttonList.length > 0) {
        this.onSelectButton(this.buttonList[0], this.buttonList[0].displayOrder);
      }
    },
    onSelectButton(item, index) {
      this.formData = {
        ...item,
        displayOrder: index,
      };
      this.$refs.form.reset();
      this.selectedId = item.buttonId;
      this.personalReportList = this.filterPersonalizedReportList(
        this.personalReportDefaultButtonList,
        this.personalReportDefaultList,
      );
      const personalizeReportSelected = this.personalReportDefaultList?.find(
        (personalReport) => personalReport.casePersonalizedReportId === item.reportId,
      );
      if (personalizeReportSelected) {
        this.personalReportList.push(personalizeReportSelected);
      }
    },
    createButtonListDefault(buttonListData) {
      const buttonList = this.sortAssignButtonList(buttonListData || []);
      const emptyButton = {
        reportId: null,
        buttonId: null,
        name: null,
        title: null,
        subTitle: '',
        buttonColorId: BUTTON_COLOR_ID,
        buttonColorName: 'silver',
        notes: '',
        displayOrder: 0,
      };
      if (!this.isPersonalisedReportFormMode) {
        const numberOfButtonList = buttonListData ? buttonListData.length : 0;
        if (numberOfButtonList < NUMBER_BUTTON_DEFAULT) {
          for (let i = 0; i < NUMBER_BUTTON_DEFAULT; i++) {
            const buttonItem = buttonList.find((item) => item.displayOrder === i);
            if (buttonItem) {
              this.buttonList.push(buttonItem);
            } else {
              this.buttonList.push(emptyButton);
            }
          }
        }
      }
    },
    sortAssignButtonList(buttonList) {
      return buttonList.sort((a, b) => a.buttonId - b.buttonId);
    },
    filterPersonalizedReportList(personalizedReportButtons, personalizedReports) {
      return personalizedReports?.filter((personalizedReports) => {
        return !personalizedReportButtons.find(
          (personalizedReportButton) =>
            personalizedReportButton.reportId === personalizedReports.casePersonalizedReportId,
        );
      });
    },
    assignButtonsData() {
      return {
        casePersonalizedReportId: this.formData.reportId,
        buttonId: this.formData.buttonId,
        name: this.formData.name,
        title: this.formData.title,
        subTitle: this.formData.subTitle,
        buttonColorItemId: this.formData.buttonColorId,
        notes: this.formData.notes,
        displayOrder: this.formData.displayOrder,
      };
    },
    getPersonalisedReportName(personalisedReportId) {
      let personalisedReport = this.personalReportDefaultList.find(
        (prItem) => prItem.casePersonalizedReportId === personalisedReportId,
      );
      if (personalisedReport) {
        return personalisedReport.title;
      }
    },
    onRemoveButton() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.removeButtonModal.open();
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    onConfirmRemoveButton() {
      const { error } = PersonalizedReportService.removeAssignButton(this.selectedId);
      if (error) {
        this.$alertErrorServerSide();
      } else {
        this.$refs.removeButtonModal.close();
        this.$alertSuccess(
          this.$t('pages/personalised-report/form/assign-button/alertSuccess/removed', {
            title: this.formData.title,
          }),
        );
        setTimeout(async () => {
          await this.$router.go();
        }, 1200);
      }
    },
    changeColor(val) {
      if (this.formData.reportId) {
        const itemColorSelected = this.colorList.find((item) => item.fieldItemId == val);

        let findIdxInBtnList = this.buttonList.findIndex((x) => x.reportId == this.formData.reportId);
        this.buttonList[findIdxInBtnList].buttonColorId = itemColorSelected.fieldItemId;
        this.buttonList[findIdxInBtnList].buttonColorName = itemColorSelected.fieldItemName;
      }
    },
    onSave() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const { error } = PersonalizedReportService.updateAssignButton(this.assignButtonsData());
            if (error) {
              this.$alertErrorServerSide();
            } else {
              this.$alertSuccess(
                this.$t('pages/personalised-report/form/assign-button/alertSuccess/save', {
                  title: this.formData.title,
                  subTitle: this.formData.subTitle,
                  personalizedReport: this.getPersonalisedReportName(this.formData.reportId),
                }),
              );
              setTimeout(async () => {
                await this.$router.go();
              }, 1200);
            }
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    onClose() {
      this.$router.push(APP_ROUTES.PERSONALISED_REPORT);
    },
  },
};
</script>

<style lang="scss">
.assign-button-width {
  width: calc(10% - 2px);
  min-height: 60px;
  .md-button-content {
    display: inline-grid !important;
  }
}
</style>
